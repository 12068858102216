import React, { useCallback, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { StateProvider } from './store-context';
import { store } from '@components/search.title/store-context';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Search from './Search';
import Widget from './Widget';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
var theme = createTheme({
    palette: {
        primary: {
            main: '#dd0504',
        },
        secondary: {
            main: '#DF430A',
        },
        error: {
            main: '#fff',
        },
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '16px',
            },
        },
    },
});
var Index = React.memo(function (_a) {
    var data = _a.data;
    var dispatch = useContext(store).dispatch;
    useEffect(function () {
        dispatch({ type: 'setData', payload: data });
    }, []);
    var handleClickAway = useCallback(function () {
        dispatch({ type: 'setFocus', payload: false });
    }, []);
    useEffect(function () {
        document.addEventListener('keydown', function (e) {
            e.key === 'Escape' && dispatch({ type: 'setFocus', payload: false });
        });
        return function () {
            document.removeEventListener('keydown', function (e) { return e; });
        };
    }, []);
    return (React.createElement(ClickAwayListener, { onClickAway: handleClickAway },
        React.createElement("div", null,
            React.createElement(Search, null),
            React.createElement(Widget, null))));
});
var SearchTitle = /** @class */ (function () {
    function SearchTitle() {
        // Монтируем компонент в DOM
        this.initComponent = function (idElement) {
            var container = document.getElementById(idElement);
            var initState = {
                historyUserPhrases: window['searchTitleInitData'].historyUserPhrases,
            };
            ReactDOM.render(React.createElement(ThemeProvider, { theme: theme },
                React.createElement(StateProvider, null,
                    React.createElement(Index, { data: initState }))), container);
        };
    }
    return SearchTitle;
}());
var searchTitle = new SearchTitle();
// Внешний API для управления состоянием корзины из внешнего мира
window['searchTitle'] = searchTitle;
