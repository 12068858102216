var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { createContext, useReducer } from 'react';
export var initialState = {
    query: '',
    isAjax: false,
    focus: false,
    historyUserPhrases: [],
    ralatedTopPhrases: [],
    sections: [],
    elements: [],
    type: 'catalog',
};
var store = createContext({
    state: initialState,
    dispatch: function () { return null; },
});
var Provider = store.Provider;
var reducer = function (state, action) {
    var payload = action === null || action === void 0 ? void 0 : action.payload;
    switch (action.type) {
        case 'setAjax':
            return __assign(__assign({}, state), { isAjax: payload });
        case 'setQuery':
            return __assign(__assign({}, state), { query: payload });
        case 'setFocus':
            return __assign(__assign({}, state), { focus: payload });
        case 'setType':
            return __assign(__assign({}, state), { type: payload });
        case 'setData':
            return __assign(__assign({}, state), payload);
        default:
            return state;
    }
};
var StateProvider = function (_a) {
    var children = _a.children;
    var _b = __read(useReducer(reducer, initialState), 2), state = _b[0], dispatch = _b[1];
    return React.createElement(Provider, { value: { state: state, dispatch: dispatch } }, children);
};
export { store, StateProvider };
