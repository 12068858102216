import React, { useContext } from 'react';
import { store } from '@components/search.title/store-context';
import Item from '@components/search.title/Widget/Items/Item';
import Skeleton from '@material-ui/lab/Skeleton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles, createStyles } from '@material-ui/core/styles';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        showAllMobile: {
            backgroundColor: "#f7f4f4",
            height: "188px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            padding: "5px",
        },
    });
});
/**
 * Разделы сайта подоходящие под поисковую фразу
 * @param param0
 * @returns
 */
var Items = React.memo(function () {
    var _a = useContext(store), _b = _a.state, elements = _b.elements, isAjax = _b.isAjax, query = _b.query, dispatch = _a.dispatch;
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var classes = useStyles();
    if (isAjax) {
        return (React.createElement("div", { id: "catalog-production-section", className: "row categoryProduct xsResponse clearfix" }, Array.apply(null, Array(isMobile ? 2 : 3)).map(function (el, i) { return (React.createElement(Skeleton, { key: i, style: { margin: '10px' }, variant: "rect", height: 250, width: 150 })); })));
    }
    if (!elements.length) {
        return null;
    }
    return (React.createElement("div", { id: "catalog-production-section", className: "row categoryProduct xsResponse clearfix" },
        elements.map(function (el) { return (React.createElement(Item, { key: el.ITEM_ID, data: el })); }),
        isMobile &&
            (query.trim().length && elements.length && !isAjax ? (React.createElement("div", { className: "item col-12 col-sm-6 col-lg-4", itemType: "http://schema.org/ImageObject" },
                React.createElement("div", { className: "product" },
                    React.createElement("div", { className: "overlay-product" },
                        React.createElement("div", { className: "image" },
                            React.createElement("a", { href: "/search/?q=".concat(query), className: classes.showAllMobile },
                                React.createElement("div", { className: "product-img-wrapper" }, "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u0432\u0441\u0435 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B"))))))) : null)));
});
export default React.memo(Items);
